import React from "react";
import TitleLine from "../TitleLine";

function H1Title(props) {
  const { children } = props;
  return (
    <div className="text-center" data-sal="slide-up">
      <h1 className="text-xl md:text-2xl lg:text-2.5xl font-bold">{children}</h1>
      <TitleLine />
    </div>
  );
}

export default H1Title;
