import React from "react";
import subTitleIcon from "@/static/icons/subTitleIcon.png";

function SubTitle(props) {
  const { children } = props;

  return (
    <h2 className="flex items-center gap-2 text-2xl color-blue font-bold mb-4">
      <img className="w-7 h-4" src={subTitleIcon} />
      {children}
    </h2>
  );
}

export default SubTitle;
