import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import H1Title from "@/components/H1Title";
import { css, cx } from "@emotion/css";
import Pagination from "rc-pagination";
import { Link } from "gatsby-plugin-intl";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const Item = (props) => {
  const { data } = props;

  return (
    <Link className="block mb-3 md:mb-16 cursor-pointer" to={`/news/detail/${data?.frontmatter.title}`}>
      <div className="flex justify-center items-center flex-col md:flex-row gap-y-3 lg:gap-y-0 gap-x-11">
        <div className="w-full md:w-72 md:h-32 lg:w-96 lg:h-44 bg-gray-100 flex-shrink-0 overflow-hidden">
          <Img
            className="h-full hover:scale-110 transform transition-all duration-300"
            fluid={data?.frontmatter.poster?.childImageSharp.fluid}
          />
        </div>
        <div>
          <h2 className="text-base font-bold mb-5 hover:text-blue-600 transition-all">{data?.frontmatter.title}</h2>
          <p
            className="text-sm mb-5 hover:text-blue-600 transition-all"
            dangerouslySetInnerHTML={{
              __html: data.frontmatter.description || data.excerpt,
            }}
            itemProp="description"
          ></p>
          <p
            className={cx(
              "text-sm",
              css`
                color: #999999;
              `
            )}
          >
            {data?.frontmatter.date} {data?.frontmatter.author}
          </p>
        </div>
      </div>
    </Link>
  );
};

const StyledPagination = (props) => {
  const { pageContext } = props;

  return (
    <div
      className={cx(
        "",
        css`
          .rc-pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            .rc-pagination-prev {
            }
            .rc-pagination-next {
            }
            .rc-pagination-disabled {
            }
            .rc-pagination-item {
              border: 1px solid #dee3e6;
              border-radius: 4px 4px 4px 4px;
              display: block;
              .rc-pagination-item-link {
                display: block;
                cursor: pointer;
                padding: 6px 13px;
                font-size: 14px;
                user-select: none;
              }
              &.rc-pagination-item-active {
                background: #1890ff;
                color: white;
                border: 1px solid #1890ff;
              }
            }
          }
        `
      )}
    >
      <Pagination
        showPrevNextJumpers={false}
        current={pageContext?.currentPage}
        pageSize={pageContext?.limit}
        total={pageContext?.numPages}
        itemRender={(e, type) => {
          if (["prev", "next", "jump-prev", "jump-next"].includes(type)) return;

          return (
            <Link className="rc-pagination-item-link" to={e === 1 ? "/news" : `/news/${e}/`}>
              {e}
            </Link>
          );
        }}
      />
    </div>
  );
};

const News = (props) => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativeDirectory: { eq: "banners" }, name: { eq: "about" }) {
        id
        publicURL
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(/news/)/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
            author
            poster {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  // const { data = {}, pageContext = {} } = props;
  const { banner = {}, allMarkdownRemark = {} } = data;
  const { nodes = [] } = allMarkdownRemark;
  const { formatMessage, locale } = useIntl();

  return (
    <div className="bg-whiter relative flex flex-col">
      <main className="flex-auto">
        <div className="w-full">
          <div className="max-w-7xl mx-auto pt-8 px-4 2xl:px-0 md:pt-12 lg:pt-16 xl:pt-24">
            <H1Title>{formatMessage({ id: "page.news.title" })}</H1Title>
            <div className="mt-8 md:mt-12 lg:mt-24">
              {console.log(nodes)}
              {nodes
                .filter((i) => i.fields.slug.includes(locale))
                .map((i, j) => (
                  <div data-sal="slide-up" data-sal-duration={200 + 100 * j}>
                    <Item key={j} data={i} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default News;
