/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-08-10 17:16:59
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-10 17:50:45
 */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { squareImage } from "@/utils/transformer";
import Img from "gatsby-image";
import { css, cx } from "@emotion/css";
import { useIntl } from "gatsby-plugin-intl";
import H1Title from "@/components/H1Title";

function Eipm() {
  const { formatMessage } = useIntl();

  const imgData = useStaticQuery(graphql`
    query {
      field: allFile(filter: { relativeDirectory: { eq: "eipm" } }) {
        nodes {
          id
          publicURL
          name
          ...squareImage
        }
      }
    }
  `);

  const imgList = imgData?.field?.nodes;

  const data = [
    {
      color: "",
      title: formatMessage({ id: "page.products.efms.workflow" }),
      desc: formatMessage({ id: "page.products.eipm.workflow.desc" }),
    },
    {
      color: "",
      title: formatMessage({ id: "page.products.efms.document" }),
      desc: formatMessage({ id: "page.products.efms.document.desc" }),
    },
    {
      color: "",
      title: formatMessage({ id: "page.products.eipm.site_photos" }),
      desc: formatMessage({ id: "page.products.efms.photosphere.desc" }),
    },
    {
      color: "",
      title: formatMessage({ id: "page.products.eipm.barometer" }),
      desc: formatMessage({ id: "page.products.eipm.barometer.desc" }),
    },
    {
      color: "",
      title: formatMessage({ id: "page.products.ecms.assignment_full" }),
      desc: formatMessage({ id: "page.products.eipm.assignment.desc" }),
    },
  ];

  return (
    <div className="pt-6 md:pt-8 lg:pt-14">
      <H1Title>{formatMessage({ id: "menu.products.eipm" })}</H1Title>
      <div
        className={cx(
          "max-w-7xl mx-auto pt-14 md:pt-16 lg:pt-20 xl:pt-24 px-4 xl:px-0 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-6"
        )}
      >
        {data.map((i, j) => (
          <div data-sal="slide-up" data-sal-duration={200 + 100 * j}>
            <div
              className={cx(
                "py-5 px-4 rounded-lg transform hover:scale-110 transition-all cursor-pointer h-full",
                css`
                  box-shadow: 0px 3px 6px 1px rgba(44, 73, 100, 0.15);
                  border: 1px solid #ffa809;
                `
              )}
              key={j}
            >
              <Img
                className="w-18 mx-auto mb-5"
                fluid={imgList.find((i) => i.name === `${j + 1}`)?.childImageSharp?.fluid}
              />
              <p className="font-bold mb-5 text-center">{i.title}</p>
              <p className="text-xs max-w-36 leading-5 text-left">{i.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Eipm;
