import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cx, css } from "@emotion/css";
import TitleLine from "@/components/TitleLine";
import SubTitle from "@/components/SubTitle";
import { useIntl } from "gatsby-plugin-intl";
import { getImageI18nFluid, squareImage } from "@/utils/transformer";
import Img from "gatsby-image";

const OurAdvantages = () => {
  const { locale, formatMessage } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      center_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "show-center1" }) {
        ...squareImage
      }
      center_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "show-center1" }) {
        ...squareImage
      }
    }
  `);
  const { center_zh = {}, center_en = {} } = data;

  const list = [
    {
      title: formatMessage({ id: "page.products.efms.whyChooseUs.title1" }),
      content: [formatMessage({ id: "page.products.efms.whyChooseUs.content1" })],
    },
    {
      title: formatMessage({ id: "page.products.efms.whyChooseUs.title2" }),
      content: [formatMessage({ id: "page.products.efms.whyChooseUs.content2" })],
    },
    {
      title: formatMessage({ id: "page.products.efms.whyChooseUs.title3" }),
      content: [formatMessage({ id: "page.products.efms.whyChooseUs.content3" })],
    },
    {
      title: formatMessage({ id: "page.products.efms.whyChooseUs.title4" }),
      content: [formatMessage({ id: "page.products.efms.whyChooseUs.content4" })],
    },
  ];
  return (
    <div
      className={cx("w-full bg-cover pt-6 pb-8 lg:pb-20 text-center max-w-7xl mx-auto px-4 md:px-6 lg:px-0 lg:mb-20")}
    >
      <SubTitle>{formatMessage({ id: "page.products.efms.whyChooseUs" })}</SubTitle>
      <div className={cx("pt-4 px-8 lg:pt-12 xl:pt-20 max-w-7xl mx-auto xl:px-0 lg:flex items-center justify-center")}>
        <div className="flex flex-col justify-around lg:h-full lg:gap-16">
          {list.slice(0, 2).map((i, j) => (
            <div
              data-sal="slide-up"
              data-sal-duration={200 + 100 * j}
              className={cx("sm:text-center mt-4 lg:text-right")}
              key={j}
            >
              <div className="text-xl font-semibold">{i.title}</div>
              <TitleLine center={false} size={"small"} startColor="#FFA809" endColor="#FFA809" />
              <ul
                className={cx(
                  "text-sm font-bold leading-6 w-auto md:w-100 mx-auto lg:my-0 xl:h-20",
                  j === 3 && "xl:ml-3",
                  css`
                    color: #999999;
                  `
                )}
              >
                {i.content.map((i, o) => (
                  <li className="inline" key={o}>
                    {i}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <Img
          data-sal="slide-up"
          data-sal-duration={100}
          className="w-full hidden lg:block mx-auto lg:max-w-xs"
          fluid={getImageI18nFluid({ zh: center_zh, en: center_en }, locale)}
        />
        <div className="flex flex-col justify-around lg:h-full lg:gap-16">
          {list.slice(2, 4).map((i, j) => (
            <div
              data-sal="slide-up"
              data-sal-duration={200 + 100 * j}
              className={cx("sm:text-center mt-4 lg:text-left")}
              key={j}
            >
              <div className="text-xl font-semibold">{i.title}</div>
              <TitleLine center={false} size={"small"} startColor="#FFA809" endColor="#FFA809" />
              <ul
                className={cx(
                  "text-sm font-bold leading-6 w-auto md:w-100 mx-auto lg:my-0 xl:h-20",
                  j === 3 && "xl:ml-3",
                  css`
                    color: #999999;
                  `
                )}
              >
                {i.content.map((i, o) => (
                  <li className="inline" key={o}>
                    {i}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurAdvantages;
