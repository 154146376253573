/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-04-21 09:15:26
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-08 17:51:26
 */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import H1Title from "@/components/H1Title";
import { css, cx } from "@emotion/css";
import { squareImage } from "@/utils/transformer";
import Img from "gatsby-image";
import bosch from "@/static/cases/bosch.png";
import roche from "@/static/cases/roche.png";
import grundfos from "@/static/cases/grundfos.png";
import uaes1 from "@/static/cases/uaes1.png";

const CasesPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      casesFiles: allFile(filter: { relativeDirectory: { eq: "cases" } }) {
        nodes {
          id
          publicURL
          name
          ...squareImage
        }
      }
    }
  `);
  const { banner = {}, casesFiles = {} } = data;
  const { locale, formatMessage } = useIntl();

  const cases = [
    {
      image: bosch,
      title: formatMessage({ id: "page.cases.classic.bosch" }),
      content: [
        formatMessage({ id: "page.cases.classic.bosch.content1" }),
        formatMessage({ id: "page.cases.classic.bosch.content2" }),
        formatMessage({ id: "page.cases.classic.bosch.content3" }),
        formatMessage({ id: "page.cases.classic.bosch.content4" }),
      ],
    },
    {
      image: roche,
      title: formatMessage({ id: "page.cases.classic.roche" }),
      content: [
        formatMessage({ id: "page.cases.classic.roche.content1" }),
        formatMessage({ id: "page.cases.classic.roche.content2" }),
        formatMessage({ id: "page.cases.classic.roche.content3" }),
      ],
    },
    {
      image: grundfos,
      title: formatMessage({ id: "page.cases.classic.grundfos" }),
      content: [
        formatMessage({ id: "page.cases.classic.grundfos.content1" }),
        formatMessage({ id: "page.cases.classic.grundfos.content2" }),
        formatMessage({ id: "page.cases.classic.grundfos.content3" }),
      ],
    },
    {
      image: uaes1,
      title: formatMessage({ id: "page.cases.classic.uaes" }),
      content: [
        formatMessage({ id: "page.cases.classic.uaes.content1" }),
        formatMessage({ id: "page.cases.classic.uaes.content2" }),
        formatMessage({ id: "page.cases.classic.uaes.content3" }),
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-whiter relative flex flex-col">
      <main className="flex-auto">
        <div className="max-w-7xl mx-auto">
          <div className="py-12 md:py-16 lg:py-20 xl:py-24">
            <H1Title>{formatMessage({ id: "page.cases.classic" })}</H1Title>
          </div>
          <div className="w-full px-4 lg:px-8 xl:px-10 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-16">
            {cases.map((item, index) => (
              <div key={index} className="h-auto">
                <div
                  data-sal="slide-up"
                  data-sal-duration={200 + 100 * index}
                  className="w-full h-44 bg-no-repeat bg-cover bg-center md:max-w-xs lg:max-w-sm"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                ></div>
                <div className="w-full" data-sal="slide-up" data-sal-duration={200 + 100 * index}>
                  <h4 className="text-base md:text-lg mb-4 mt-6 text-center">{item.title}</h4>
                  <ul className="color-gray-66 text-sm leading-4 md:leading-6 list-outside list-disc pl-4 text-left">
                    {item.content.map((p, i) => (
                      <li key={i}>{p}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="max-w-7xl mx-auto pb-10 md:pb-16 lg:pb-24 xl:pb-32">
          <div className="py-12 md:py-16 lg:py-20 xl:py-24">
            <H1Title>{formatMessage({ id: "page.cases.customer" })}</H1Title>
          </div>
          <div className="grid grid-cols-4 xl:grid-cols-5 gap-5 px-3 xl:px-0">
            {casesFiles?.nodes
              ?.sort((i, j) => i.name - j.name)
              .map((i, j) => (
                <div
                  data-sal="slide-up"
                  data-sal-duration={200 + 90 * j}
                  className={cx(
                    "flex justify-center",
                    css`
                      border: 1px dashed #aaaaab;
                    `
                  )}
                >
                  <Img className="w-full" key={j} fluid={i?.childImageSharp?.fluid} alt={i.name} />
                </div>
              ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default CasesPage;
