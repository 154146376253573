/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-08-10 14:49:07
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-10 17:49:40
 */

import H1Title from "@/components/H1Title";
import up from "@/static/industrial/up.png";
import down from "@/static/industrial/down.png";
import React from "react";
import { css, cx } from "@emotion/css";
import { graphql, useStaticQuery } from "gatsby";
import { squareImage } from "@/utils/transformer";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const ItemLeftContent = (props) => {
  const { formatMessage } = useIntl();
  const { color = 1, data = {} } = props;
  const { content } = data;

  const bgColor = color === 1 ? "#E9DECD" : "#D0EFE6";
  const titleColor = color === 1 ? "#E30000" : "#00A13B";
  const titleIcon = color === 1 ? down : up;
  const title =
    color === 1
      ? formatMessage({ id: "page.products.industrial.unused" })
      : formatMessage({ id: "page.products.industrial.used" });

  return (
    <div
      className={cx(
        "px-4 py-7 h-full",
        css`
          max-width: 286px;
          min-height: 188px;
          background-color: ${bgColor};
        `
      )}
    >
      <h3
        className={cx(
          "text-base flex justify-start items-center gap-2 mb-3 font-bold",
          css`
            color: ${titleColor};
          `
        )}
      >
        <img src={titleIcon} />
        {title}
      </h3>
      {typeof content === "string" ? (
        <p
          className={cx(
            "font-bold text-sm leading-6 lg:leading-8",
            css`
              color: #404040;
            `
          )}
        >
          {content}
        </p>
      ) : (
        <ul
          className={cx(
            "font-bold text-sm leading-6 lg:leading-8",
            css`
              color: #404040;
            `
          )}
        >
          {content.map((i, j) => (
            <li key={j}>{i}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Item = (props) => {
  const { data = {}, image } = props;
  const { title, desc, content } = data;

  return (
    <div className="max-w-7xl flex flex-col lg:flex-row px-6 lg:px-4 xxl:px-0 justify-center items-center gap-10 mx-auto py-6 lg:py-16">
      <div data-sal="slide-right" data-sal-duration="500">
        <h2 className="text-2xl font-bold mb-5 lg:max-w-lg xl:max-w-xl">{title}</h2>
        <p className="lg:max-w-lg xl:max-w-xl text-sm mb-9 leading-5 lg:leading-6">{desc}</p>
        <div className="grid grid-cols-2 gap-3 justify-items-center items-center max-w-full lg:max-w-lg xl:max-w-xl">
          {content.map((i, j) => (
            <ItemLeftContent key={j} color={j + 1} data={i} />
          ))}
        </div>
      </div>
      <div className="flex-1 flex-shrink-0 w-full" data-sal="slide-left" data-sal-duration="500">
        <Img className="w-full" fluid={image?.childImageSharp?.fluid} />
      </div>
    </div>
  );
};

const IndustrialPage = () => {
  const { formatMessage } = useIntl();

  const imgData = useStaticQuery(graphql`
    query {
      field: allFile(filter: { relativeDirectory: { eq: "industrial" } }) {
        nodes {
          id
          publicURL
          name
          ...squareImage
        }
      }
    }
  `);

  const imgList = imgData?.field?.nodes;

  const data = [
    {
      title: formatMessage({ id: "page.products.industrial.title1" }),
      desc: formatMessage({ id: "page.products.industrial.desc1" }),
      content: [
        {
          content: [
            formatMessage({ id: "page.products.industrial.content1.list1.t1" }),
            formatMessage({ id: "page.products.industrial.content1.list1.t2" }),
            formatMessage({ id: "page.products.industrial.content1.list1.t3" }),
          ],
        },
        {
          content: [
            formatMessage({ id: "page.products.industrial.content1.list2.t1" }),
            formatMessage({ id: "page.products.industrial.content1.list2.t2" }),
            formatMessage({ id: "page.products.industrial.content1.list2.t3" }),
          ],
        },
      ],
    },
    {
      title: formatMessage({ id: "page.products.industrial.title2" }),
      desc: formatMessage({ id: "page.products.industrial.desc2" }),
      content: [
        {
          content: formatMessage({ id: "page.products.industrial.content2.p1" }),
        },
        {
          content: formatMessage({ id: "page.products.industrial.content2.p2" }),
        },
      ],
    },
    {
      title: formatMessage({ id: "page.products.industrial.title3" }),
      desc: formatMessage({ id: "page.products.industrial.desc3" }),
      content: [
        {
          content: [
            formatMessage({ id: "page.products.industrial.content3.list1.t1" }),
            formatMessage({ id: "page.products.industrial.content3.list1.t2" }),
            formatMessage({ id: "page.products.industrial.content3.list1.t3" }),
            formatMessage({ id: "page.products.industrial.content3.list1..t4" }),
          ],
        },
        {
          content: [
            formatMessage({ id: "page.products.industrial.content3.list2.t1" }),
            formatMessage({ id: "page.products.industrial.content3.list2.t2" }),
            formatMessage({ id: "page.products.industrial.content3.list2.t3" }),
            formatMessage({ id: "page.products.industrial.content3.list2.t4" }),
          ],
        },
      ],
    },
    {
      title: formatMessage({ id: "page.products.industrial.title4" }),
      desc: formatMessage({ id: "page.products.industrial.desc4" }),
      content: [
        {
          content: formatMessage({ id: "page.products.industrial.content4.p1" }),
        },
        {
          content: [
            formatMessage({ id: "page.products.industrial.content4.list1.t1" }),
            formatMessage({ id: "page.products.industrial.content4.list1.t2" }),
            formatMessage({ id: "page.products.industrial.content4.list1.t3" }),
          ],
        },
      ],
    },
    {
      title: formatMessage({ id: "page.products.industrial.title5" }),
      desc: formatMessage({ id: "page.products.industrial.desc5" }),
      content: [
        {
          content: [
            formatMessage({ id: "page.products.industrial.content5.list1.t1" }),
            formatMessage({ id: "page.products.industrial.content5.list1.t2" }),
            formatMessage({ id: "page.products.industrial.content5.list1.t3" }),
          ],
        },
        {
          content: [
            formatMessage({ id: "page.products.industrial.content5.list2.t1" }),
            formatMessage({ id: "page.products.industrial.content5.list2.t2" }),
            formatMessage({ id: "page.products.industrial.content5.list2.t3" }),
          ],
        },
      ],
    },
  ];
  return (
    <div className="pt-6 md:pt-8 lg:pt-14">
      <H1Title>{formatMessage({ id: "menu.products.industrial" })}</H1Title>
      <div className="pt-7">
        {data.map((i, j) => (
          <Item data={i} key={j} image={imgList.find((i) => i.name === `${j + 1}`)} />
        ))}
      </div>
    </div>
  );
};

export default IndustrialPage;
