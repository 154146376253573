import React from "react";
import workflow from "@/static/efms/icons/workflow.png";
import document from "@/static/efms/icons/document.png";
import card from "@/static/efms/icons/card.png";
import training from "@/static/efms/icons/training.png";
import wharf from "@/static/efms/icons/wharf.png";
import meeting from "@/static/efms/icons/meeting.png";
import spare from "@/static/efms/icons/spare.png";
import project from "@/static/efms/icons/project.png";
import panorama from "@/static/efms/icons/panorama.png";
import blueprint from "@/static/efms/icons/blueprint.png";
import keys from "@/static/efms/icons/keys.png";
import parking from "@/static/efms/icons/parking.png";
import entrance from "@/static/efms/icons/entrance.png";
import safety from "@/static/efms/icons/safety.png";
import elimination from "@/static/efms/icons/elimination.png";
import check from "@/static/efms/icons/check.png";
import carryout from "@/static/efms/icons/carryout.png";
import ticket from "@/static/efms/icons/ticket.png";
import itraining from "@/static/efms/icons/itraining.png";
import { cx, css } from "@emotion/css";
import { useIntl } from "gatsby-plugin-intl";

const OtherModules = () => {
  const { formatMessage } = useIntl();

  const colorArr = ["#FFA809", "#1890FF", "#1BD181"];
  const data = [
    {
      color: colorArr[0],
      icon: workflow,
      title: formatMessage({ id: "page.products.efms.workflow" }),
      desc: formatMessage({ id: "page.products.efms.workflow.desc" }),
    },
    {
      color: colorArr[0],
      icon: document,
      title: formatMessage({ id: "page.products.efms.document" }),
      desc: formatMessage({ id: "page.products.efms.document.desc" }),
    },
    {
      color: colorArr[0],
      icon: card,
      title: formatMessage({ id: "page.products.efms.card" }),
      desc: formatMessage({ id: "page.products.efms.card.desc" }),
    },
    {
      color: colorArr[0],
      icon: training,
      title: formatMessage({ id: "page.products.efms.itraining" }),
      desc: formatMessage({ id: "page.products.efms.itraining.desc" }),
    },
    {
      color: colorArr[0],
      icon: wharf,
      title: formatMessage({ id: "page.products.efms.wharf" }),
      desc: formatMessage({ id: "page.products.efms.wharf.desc" }),
    },
    {
      color: colorArr[0],
      icon: meeting,
      title: formatMessage({ id: "page.products.efms.meeting.name" }),
      desc: formatMessage({ id: "page.products.efms.meeting.desc" }),
    },
    {
      color: colorArr[1],
      icon: spare,
      title: formatMessage({ id: "page.products.efms.spare" }),
      desc: formatMessage({ id: "page.products.efms.spare.desc" }),
    },
    {
      color: colorArr[1],
      icon: project,
      title: formatMessage({ id: "page.products.efms.pm" }),
      desc: formatMessage({ id: "page.products.efms.project.desc" }),
    },
    {
      color: colorArr[1],
      icon: panorama,
      title: formatMessage({ id: "page.products.ecms.photosphere" }),
      desc: formatMessage({ id: "page.products.efms.photosphere.desc" }),
    },
    {
      color: colorArr[1],
      icon: blueprint,
      title: formatMessage({ id: "page.products.efms.blueprint" }),
      desc: formatMessage({ id: "page.products.efms.blueprint.desc" }),
    },
    {
      color: colorArr[1],
      icon: keys,
      title: formatMessage({ id: "page.products.efms.keys" }),
      desc: formatMessage({ id: "page.products.efms.keys.desc" }),
    },
    {
      color: colorArr[1],
      icon: parking,
      title: formatMessage({ id: "page.products.efms.park" }),
      desc: formatMessage({ id: "page.products.efms.park.desc" }),
    },
    {
      color: colorArr[1],
      icon: entrance,
      title: formatMessage({ id: "page.products.efms.entrance" }),
      desc: formatMessage({ id: "page.products.efms.entrance.desc" }),
    },
    {
      color: colorArr[2],
      icon: safety,
      title: formatMessage({ id: "page.products.efms.4_safety1" }),
      desc: formatMessage({ id: "page.products.efms.safety.desc" }),
    },
    {
      color: colorArr[2],
      icon: elimination,
      title: formatMessage({ id: "page.products.efms.security" }),
      desc: formatMessage({ id: "page.products.efms.security.desc" }),
    },
    {
      color: colorArr[2],
      icon: check,
      title: formatMessage({ id: "page.products.efms.danger" }),
      desc: formatMessage({ id: "page.products.efms.danger.desc" }),
    },
    {
      color: colorArr[2],
      icon: carryout,
      title: formatMessage({ id: "page.products.efms.carryout" }),
      desc: formatMessage({ id: "page.products.efms.carryout.desc" }),
    },
    {
      color: colorArr[2],
      icon: ticket,
      title: formatMessage({ id: "page.products.efms.ticket" }),
      desc: formatMessage({ id: "page.products.efms.ticket.desc" }),
    },
    {
      color: colorArr[2],
      icon: itraining,
      title: formatMessage({ id: "page.products.efms.training" }),
      desc: formatMessage({ id: "page.products.efms.training.desc" }),
    },
  ];

  return (
    <div className="max-w-7xl mx-auto py-8 md:py-12 lg:py-28 px-4 xl:px-0">
      <h3 className={cx("text-2xl font-bold mb-8 md:mb-14 text-left")}>
        {formatMessage({ id: "page.products.efms.others" })}
      </h3>
      <div className={cx("grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-6")}>
        {data.map((i, j) => (
          <div data-sal="slide-up" data-sal-duration={200 + 100 * j}>
            <div
              className={cx(
                "py-5 px-4 rounded-lg transform hover:scale-110 transition-all cursor-pointer h-full",
                css`
                  box-shadow: 0px 3px 6px 1px rgba(44, 73, 100, 0.15);
                  border: 1px solid ${i.color};
                `
              )}
              key={j}
            >
              <img className="w-18 mx-auto mb-5" src={i.icon} alt={i.title} />
              <p className="font-bold mb-5">{i.title}</p>
              <p className="text-xs max-w-36 leading-5 text-left">
                {i.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherModules;
