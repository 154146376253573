import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import "swiper/swiper.min.css";
import SwiperCore, { EffectCoverflow, Autoplay } from "swiper/core";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import { css, cx } from "@emotion/css";
import H1Title from "@/components/H1Title";
import { squareImage } from "@/utils/transformer";
import Img from "gatsby-image";
import image from "@/static/about/about2.png";
import img1 from "@/static/about/2.jpg";
import img2 from "@/static/about/3.jpg";
import img3 from "@/static/about/4.jpg";
import img4 from "@/static/about/5.jpg";

SwiperCore.use([EffectCoverflow, Autoplay]);

const AboutPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      certs: allFile(filter: { relativeDirectory: { eq: "certs" } }) {
        nodes {
          id
          name
          publicURL
          ...squareImage
        }
      }
    }
  `);

  const { locale, formatMessage } = useIntl();

  const onceptData = [
    {
      image: img1,
      title: formatMessage({ id: "page.about.oncept.title1" }),
      content: [
        formatMessage({ id: "page.about.oncept.title1desc1" }),
        formatMessage({ id: "page.about.oncept.title1desc2" }),
        formatMessage({ id: "page.about.oncept.title1desc3" }),
      ],
    },
    {
      image: img2,
      title: formatMessage({ id: "page.about.oncept.title2" }),
      content: [
        formatMessage({ id: "page.about.oncept.title2desc1" }),
        formatMessage({ id: "page.about.oncept.title2desc2" }),
        formatMessage({ id: "page.about.oncept.title2desc3" }),
      ],
    },
    {
      image: img3,
      title: formatMessage({ id: "page.about.oncept.title3" }),
      content: [
        formatMessage({ id: "page.about.oncept.title3desc1" }),
        formatMessage({ id: "page.about.oncept.title3desc2" }),
        formatMessage({ id: "page.about.oncept.title3desc3" }),
        formatMessage({ id: "page.about.oncept.title3desc4" }),
      ],
    },
    {
      image: img4,
      title: formatMessage({ id: "page.about.oncept.title4" }),
      content: [
        formatMessage({ id: "page.about.oncept.title4desc1" }),
        formatMessage({ id: "page.about.oncept.title4desc2" }),
        formatMessage({ id: "page.about.oncept.title4desc3" }),
        formatMessage({ id: "page.about.oncept.title4desc4" }),
        formatMessage({ id: "page.about.oncept.title4desc5" }),
        formatMessage({ id: "page.about.oncept.title4desc6" }),
        formatMessage({ id: "page.about.oncept.title4desc7" }),
      ],
    },
  ];
  const { certs = {} } = data;
  const { nodes: certList = [] } = certs;

  return (
    <div className="min-h-screen bg-whiter relative flex flex-col">
      <main className="flex-auto">
        <div className="w-full">
          <div className="max-w-7xl mx-auto py-8 px-4 2xl:px-0 md:py-12 lg:py-16 xl:py-24">
            <div className="mb-4">
              <H1Title>{formatMessage({ id: "menu.about" })}</H1Title>
            </div>
            <div className="md:flex md:items-center lg:items-start lg:mt-10">
              <img className="w-full h-auto md:w-auto md:h-60 lg:h-72" src={image} alt="About Us" />
              <div
                className="color-gray-66 text-sm md:text-base leading-6 md:leading-8 md:flex-auto md:mx-4 lg:mx-8 xl:mx-10"
                style={{
                  textIndent: "2rem",
                }}
              >
                <p className="pt-6">{formatMessage({ id: "page.about.introduce1" })}</p>
                <p className="pt-6">{formatMessage({ id: "page.about.introduce2" })}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-f9">
          <div className="max-w-7xl mx-auto py-8 px-4 2xl:px-0 md:py-12 lg:py-16 xl:py-24">
            <div className="mb-4">
              <H1Title>{formatMessage({ id: "page.about.vision" })}</H1Title>
            </div>
            <p
              className={cx(
                "mt-11",
                css`
                  color: #434343;
                `
              )}
            >
              {formatMessage({ id: "page.about.vision.content" })}
            </p>
            <div className="w-full grid grid-cols-2 md:grid-cols-4 pt-10">
              {onceptData.map((item, index) => (
                <div
                  className="oncept-card relative overflow-hidden cursor-pointer"
                  key={index}
                  data-sal="slide-up"
                  data-sal-duration={200 + 100 * index}
                >
                  <img className="w-full h-auto" src={item.image} alt={item.title} />
                  <div className="oncept-card-text-wrapper absolute bottom-0 w-full text-white text-center py-7 transition duration-700 ease-in-out">
                    <h4 className="text-xl md:text-2xl">{item.title}</h4>
                    <ul className="mt-3 text-xs leading-6 transition opacity-0 duration-700 ease-in-out">
                      {item.content?.map((c, cIndex) => (
                        <li key={cIndex}>{c}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="max-w-7xl mx-auto py-8 px-4 2xl:px-0 md:py-12 lg:py-16 xl:py-24">
            <div className="mb-4">
              <H1Title>{formatMessage({ id: "page.about.certificate" })}</H1Title>
            </div>
            <div className="w-full mt-8">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 lg:gap-14">
                {certList
                  .sort((i, j) => i.name - j.name)
                  .map((i, j) => (
                    <div key={j} data-sal="slide-up" data-sal-duration={200 + 100 * j}>
                      <Img className="h-auto max-h-96" fluid={i?.childImageSharp?.fluid} alt="Certificate" />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AboutPage;
