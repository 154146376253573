/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-07-19 14:21:16
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-26 10:05:37
 */
import { graphql } from "gatsby";

/**
 * @name gastby image 尺寸转换
 * @description 解决图片加载问题
 * @example
 *
  import { graphql, useStaticQuery } from "gatsby";
  import { squareImage } from "@/utils/transformer";
  import Img from "gatsby-image";

  const data = useStaticQuery(graphql`
    query {
      homeBg: file(relativeDirectory: { eq: "home" }, name: { eq: "index_bg" }) {
        ...squareImage
      }
    }
  `);

  <Img fluid={homeBg?.childImageSharp?.fluid} alt="" />
 */
export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const getImageI18nFluid = (data, locale) => {
  return data[locale]?.childImageSharp?.fluid ?? undefined;
};
