/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-06-29 11:47:05
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-09 11:56:48
 */
import React from "react";
import { cx, css } from "@emotion/css";

export const Title = (props) => {
  const { children } = props;

  return <p className={cx("text-2xl font-bold mb-8")}>{children}</p>;
};

export const Desc = (props) => {
  const { children } = props;

  return <p className="text-sm">{children}</p>;
};
