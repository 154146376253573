/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-06-29 14:19:36
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-09 13:54:40
 */
import React from "react";
import { cx, css } from "@emotion/css";

export const ListIcon = () => (
  <i
    className={cx(
      "w-4 h-4 rounded-full mr-1 inline-flex justify-center items-center",
      css`
        border: 1px solid #707070;
        &::after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border: 1px solid #707070;
          border-radius: 9999px;
        }
      `
    )}
  ></i>
);

function List(props) {
  const { list, col = 2 } = props;

  return (
    <div className={cx("grid gap-x-8", `grid-cols-${col}`)}>
      {list.map((i, j) => (
        <div key={j} className={cx("mt-8", i.col && `col-span-${i.col}`)}>
          {i.title && (
            <p className="font-bold mb-4 pl-6 relative">
              <div
                className={cx(
                  "absolute left-0",
                  css`
                    min-height: 24px;
                  `
                )}
              >
                <ListIcon />
              </div>
              {i.title}
            </p>
          )}
          {i.desc?.map((k, kl) => (
            <p
              key={kl}
              className={cx(
                "text-sm pl-6 relative",
                css`
                  color: #999999;
                `
              )}
            >
              {!i.title && (
                <div
                  className={cx(
                    "absolute left-0",
                    css`
                      min-height: 24px;
                    `
                  )}
                >
                  <ListIcon />
                </div>
              )}
              {k}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
}

export default List;
