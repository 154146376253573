import React, { useEffect, useRef, useState } from "react";
import { Header, Footer, BackTop } from "@/components";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { cx, css } from "@emotion/css";
import ProductsEfmsPage from "../views/products/efms";
import CasesPage from "../views/cases";
import News from "@/templetes/news";
import AboutPage from "../views/about";
import ContactPage from "../views/contact";
import { Element } from "react-scroll";
import { graphql, useStaticQuery } from "gatsby";
import VideoPlayIcon from "@/static/video_play.png";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";
import IndustrialPage from "@/views/products/industrial";
import Eipm from "@/views/products/eipm";

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      homeBg: file(relativeDirectory: { eq: "home" }, name: { eq: "index_bg" }) {
        ...squareImage
      }
    }
  `);

  const { formatMessage, locale } = useIntl();

  const { homeBg = {} } = data;

  const videoRef = useRef();

  const [navShow, setNavShow] = useState(true);
  const [toTopShow, setToTopShow] = useState(false);
  const [videoStart, setVideoStart] = useState(false);

  useEffect(() => {
    const container = document.querySelector("#gatsby-focus-wrapper");
    let scrollHeight = 0;
    container?.addEventListener("scroll", (e) => {
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      setToTopShow(scrollTop > clientHeight);
      setNavShow(true);
      // setNavShow(scrollHeight > scrollTop);
      scrollHeight = scrollTop;
    });
    return () => {
      container?.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <div className="relative w-full">
      <Header
        {...props}
        className={cx(
          "fixed -top-20 z-20 transform transition",
          navShow && "translate-y-20",
          css`
            background: rgba(0, 23, 52, 0.5);
          `
        )}
        light
        transparent
        link
      />
      <main id="index-page" className={cx("top-0 w-full z-0")}>
        <div>
          <Element name="home" className="relative">
            <div className={cx("w-screen h-screen relative")}>
              <Img
                className="absolute top-0 bottom-0 h-screen w-screen"
                fluid={homeBg?.childImageSharp?.fluid}
                alt=""
              />
              <div className="absolute top-0 bottom-0 left-0 right-0">
                <div className="text-white flex justify-center items-center flex-col h-5/6 select-none">
                  <h1
                    className={cx(
                      "text-3xl md:text-5xl lg:text-6xl xl:text-7xl flex gap-11 font-bold mb-6 xl:mb-11 mt-8",
                      locale === "en" ? "flex-col justify-center items-center" : ""
                    )}
                    data-sal="zoom-in"
                    data-sal-duration={500}
                  >
                    <span>{formatMessage({ id: "page.home.title1" })}</span>
                    <span>{formatMessage({ id: "page.home.title2" })}</span>
                  </h1>
                  <p
                    className={cx(
                      "text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center w-full",
                      css`
                        color: #b6c0cc;
                      `
                    )}
                    data-sal="zoom-in"
                    data-sal-duration={500}
                    data-sal-delay={100}
                  >
                    —{" "}
                    <span
                      className={cx(
                        locale === "en"
                          ? css`
                              display: inline-block;
                              max-width: 70%;
                              text-align: center;
                            `
                          : ""
                      )}
                    >
                      {formatMessage({ id: "page.home.title3" })}
                    </span>{" "}
                    —
                  </p>
                </div>
                <Footer
                  light
                  textColor="#fff"
                  className={cx(
                    "absolute bottom-0",
                    css`
                      background: rgba(0, 0, 0, 0.6);
                      z-index: 11;
                    `
                  )}
                />
              </div>
              <div
                className={cx(
                  "absolute top-0 bottom-0 left-0 right-0 z-10 flex justify-center items-center",
                  css`
                    &:hover {
                      img {
                        opacity: 1;
                      }
                    }
                  `
                )}
              >
                <img
                  className="opacity-1 sm:opacity-0 w-20 h-20 md:w-28 md:h-28 transition cursor-pointer"
                  src={VideoPlayIcon}
                  onClick={() => {
                    setVideoStart(true);
                    videoRef.current?.play();
                  }}
                  alt="曼捷科eFMS宣传视频开始按钮"
                />
              </div>
              <video
                ref={videoRef}
                className={cx(
                  "absolute top-0 bottom-0 left-0 right-0 h-screen w-screen",
                  videoStart ? "block" : "hidden",
                  css`
                    backdrop-filter: blur(20px);
                    z-index: 12;
                  `
                )}
                controls
              >
                <source
                  src={
                    locale === "zh"
                      ? "https://web-1256637595.cos.ap-shanghai.myqcloud.com/%E6%9B%BC%E6%8D%B7%E7%A7%9170.mp4"
                      : "https://web-1256637595.cos.ap-shanghai.myqcloud.com/%E6%9B%BC%E6%8D%B7%E7%A7%91100%E8%8B%B1%E6%96%87%E5%AD%97%E5%B9%95.mp4"
                  }
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </Element>
          <Element name="efms">
            <ProductsEfmsPage />
          </Element>
          <Element name="industrial">
            <IndustrialPage />
          </Element>
          <Element name="eipm">
            <Eipm />
          </Element>
          <Element name="cases">
            <CasesPage />
          </Element>
          <Element name="news">
            <News />
          </Element>
          <Element name="about">
            <AboutPage />
          </Element>
          <Element name="contact">
            <ContactPage />
          </Element>
        </div>
        <BackTop link scrollShow={toTopShow} />
      </main>
    </div>
  );
};

export default IndexPage;
