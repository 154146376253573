import { css, cx } from "@emotion/css";
import React from "react";

function TitleLine({ startColor = "#49CCF8", endColor = "#0081FF", deg = 90, size = "normal" }) {
  return (
    <div
      className={cx(
        "w-18 h-1 rounded inline-block",
        size === "mormal" && "mt-4 mb-6",
        size === "small" && "mt-1 mb-4",
        css`
          background: linear-gradient(${deg}deg, ${startColor} 0%, ${endColor} 100%);
        `
      )}
    ></div>
  );
}

export default TitleLine;
