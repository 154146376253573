import React from "react";
import { cx, css } from "@emotion/css";
import OtherModules from "./otherModules";
import Box from "@/components/ModuleTemplete/box";
import { Title, Desc } from "@/components/ModuleTemplete/text";
import List, { ListIcon } from "@/components/ModuleTemplete/list";
import { graphql, useStaticQuery } from "gatsby";
import { getImageI18nFluid, squareImage } from "@/utils/transformer";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const Templete = (props) => {
  const { bg, main, data, layout = "left", type = 1, col } = props;

  const { title, desc, list, list2, boxClassNames, mainImgClassNames } = data;

  return (
    <Box bg={bg}>
      <div
        className={cx(
          "max-w-7xl mx-auto flex justify-center lg:items-center gap-3 lg:gap-20 flex-col-reverse lg:flex-row px-4 md:px-6 lg:px-0",
          layout === "right" && "lg:flex-row-reverse"
        )}
      >
        <div
          className={cx("p-2 lg:p-0 mx-auto w-full", mainImgClassNames)}
          data-sal={layout === "right" ? "slide-left" : "slide-right"}
          data-sal-duration="500"
        >
          {typeof main === "string" ? (
            <img src={main} />
          ) : (
            <Img className="w-full" fluid={main?.childImageSharp?.fluid ?? main} alt="" />
          )}
        </div>
        <div
          className={cx("bg-white text-left py-3 px-4", boxClassNames ?? "lg:max-w-lg lg:py-9 lg:px-11")}
          data-sal={layout === "right" ? "slide-right" : "slide-left"}
          data-sal-duration="500"
        >
          <Title>{title}</Title>
          {desc !== undefined && (
            <Desc>
              {typeof desc === "string" ? (
                desc
              ) : (
                <ul>
                  {desc?.map((i, j) => (
                    <li key={j}>{i}</li>
                  ))}
                </ul>
              )}
            </Desc>
          )}
          {type === 1 ? (
            <List col={col} list={list} />
          ) : type === 2 ? (
            <div>
              <ul className="list-disc list-inside text-sm mb-8">
                {list.map((i, j) => (
                  <li className="mb-2" key={j}>
                    {i}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </Box>
  );
};

const ModuleVisitor = ({ main, bg }) => {
  const { formatMessage } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.visitor" }),
    desc: formatMessage({ id: "page.products.efms.visitor.desc" }),
    list: [
      {
        title: formatMessage({ id: "page.products.efms.visitor.list.title1" }),
        desc: [formatMessage({ id: "page.products.efms.visitor.list.desc1" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.visitor.list.title2" }),
        desc: [formatMessage({ id: "page.products.efms.visitor.list.desc2" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.visitor.list.title3" }),
        desc: [formatMessage({ id: "page.products.efms.visitor.list.desc3" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.visitor.list.title4" }),
        desc: [formatMessage({ id: "page.products.efms.visitor.list.desc4" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.visitor.list.title5" }),
        desc: [formatMessage({ id: "page.products.efms.visitor.list.desc5" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.visitor.list.title6" }),
        desc: [formatMessage({ id: "page.products.efms.visitor.list.desc6" })],
      },
    ],
  };
  return <Templete bg={bg} main={main} data={data} />;
};

const ModuleInspection = ({ main }) => {
  const { formatMessage } = useIntl();

  const data = {
    boxClassNames: "lg:max-w-xl lg:py-0 lg:px-0",
    title: formatMessage({ id: "page.products.efms.inspection" }),
    desc: formatMessage({ id: "page.products.efms.inspection.desc" }),
    list: [
      {
        title: formatMessage({ id: "page.products.efms.inspection.list.title1" }),
        desc: [formatMessage({ id: "page.products.efms.inspection.list.desc1" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.inspection.list.title2" }),
        desc: [formatMessage({ id: "page.products.efms.inspection.list.desc2" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.inspection.list.title3" }),
        desc: [formatMessage({ id: "page.products.efms.inspection.list.desc3" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.inspection.list.title4" }),
        desc: [formatMessage({ id: "page.products.efms.inspection.list.desc4" })],
      },
    ],
  };
  return <Templete main={main} data={data} layout="right" />;
};

const ModuleEqp = ({ main, bg }) => {
  const { formatMessage } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.eqp" }),
    desc: formatMessage({ id: "page.products.efms.eqp.desc" }),
    list: [
      {
        title: formatMessage({ id: "page.products.efms.eqp.list.title1" }),
        desc: [formatMessage({ id: "page.products.efms.eqp.list.desc1" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.eqp.list.title2" }),
        desc: [formatMessage({ id: "page.products.efms.eqp.list.desc2" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.eqp.list.title3" }),
        desc: [formatMessage({ id: "page.products.efms.eqp.list.desc3" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.eqp.list.title4" }),
        desc: [formatMessage({ id: "page.products.efms.eqp.list.desc4" })],
      },
    ],
  };
  return <Templete bg={bg} main={main} data={data} />;
};

const ModuleConstruction = ({ main, main2 }) => {
  const { formatMessage } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.construction" }),
    desc: "",
    list: [
      formatMessage({ id: "page.products.efms.construction.list.content1" }),
      formatMessage({ id: "page.products.efms.construction.list.content2" }),
      formatMessage({ id: "page.products.efms.construction.list.content3" }),
      formatMessage({ id: "page.products.efms.construction.list.content4" }),
    ],
  };

  return (
    <Box>
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row justify-center items-center gap-16 px-4 md:px-6 lg:px-0">
        <div className="text-left w-full lg:w-auto" data-sal="slide-right" data-sal-duration="500">
          <Title>{data.title}</Title>
          <div className="max-w-full lg:max-w-lg">
            <ul className="mb-9 list-disc list-outside pl-4">
              {data.list.map((i, j) => (
                <li key={j} className="mb-1">
                  {i}
                </li>
              ))}
            </ul>
          </div>
          <div
            className={cx(
              "max-w-full max-h-full",
              css`
                width: 466px;
              `
            )}
          >
            <Img className={cx("w-full h-full")} fluid={main} alt={data.title} />
          </div>
        </div>
        <div
          className={cx(
            "flex items-end w-full max-w-full",
            css`
              width: 729px;
            `
          )}
          data-sal="slide-left"
          data-sal-duration="500"
        >
          <Img className={cx("w-full")} fluid={main2} alt={data.title} />
        </div>
      </div>
    </Box>
  );
};

const ModuleMeal = ({ main, bg }) => {
  const { formatMessage } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.meal" }),
    desc: formatMessage({ id: "page.products.efms.meal.desc" }),
    list: [
      {
        title: formatMessage({ id: "page.products.efms.meal.list.title1" }),
        desc: [
          formatMessage({ id: "page.products.efms.meal.list.desc1" }),
          formatMessage({ id: "page.products.efms.meal.list.desc2" }),
        ],
      },
      {
        title: formatMessage({ id: "page.products.efms.meal.list.title2" }),
        desc: [formatMessage({ id: "page.products.efms.meal.list.desc3" })],
      },
    ],
  };

  return <Templete bg={bg} main={main} data={data} col={1} />;
};

const ModuleDesk = ({ main, main2 }) => {
  const { formatMessage, locale } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.office" }),
    desc: formatMessage({ id: "page.products.efms.office.desc" }),
    list: [
      {
        title: formatMessage({ id: "page.products.efms.office.list.title1" }),
        desc: [formatMessage({ id: "page.products.efms.office.list.desc1" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.office.list.title2" }),
        desc: [formatMessage({ id: "page.products.efms.office.list.desc2" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.office.list.title3" }),
        desc: [formatMessage({ id: "page.products.efms.office.list.desc3" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.office.list.title4" }),
        desc: [formatMessage({ id: "page.products.efms.office.list.desc4" })],
      },
    ],
  };

  return (
    <Box>
      <div className="flex flex-col max-w-7xl mx-auto lg:flex-row justify-center items-center gap-16 px-4 md:px-6 lg:px-0">
        <div
          className={cx("text-left relative", locale === "en" ? "flex-1" : "")}
          data-sal="slide-right"
          data-sal-duration="500"
        >
          <Title>{data.title}</Title>
          <div className={cx(locale === "en" ? "" : "lg:whitespace-nowrap lg:max-w-xs")}>
            <Desc>{data.desc}</Desc>
          </div>
          <div className={cx(locale === "en" ? "" : "lg:max-w-xs")}>
            <List list={data.list} />
          </div>
        </div>
        <div
          className={cx(
            "flex items-end w-full flex-col lg:flex-row gap-2 lg:gap-0",
            locale === "en" ? "lg:max-w-2xl" : ""
          )}
          data-sal="slide-left"
          data-sal-duration="500"
        >
          <div
            className={cx(
              "max-w-full max-h-full",
              css`
                width: 310px;
                height: 249px;
              `
            )}
          >
            <Img className={cx("w-full")} fluid={main} alt={data.title} />
          </div>
          <div
            className={cx(
              "max-w-full max-h-full",
              css`
                width: 493px;
                height: 500px;
              `
            )}
          >
            <Img className={cx("w-full")} fluid={main2} alt={data.title} />
          </div>
        </div>
      </div>
    </Box>
  );
};

const ModuleOrder = ({ main, main2, main3, bg }) => {
  const { formatMessage } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.workorder" }),
  };

  return (
    <Box bg={bg}>
      <div className="max-w-7xl text-left mx-auto px-4 md:px-6 lg:px-0">
        <Title>{data.title}</Title>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-6 max-w-7xl mx-auto px-4 md:px-6 lg:px-0">
        <div data-sal="slide-right" className="max-w-full" data-sal-duration="500">
          <p className="font-bold mb-7">
            <ListIcon />
            {formatMessage({ id: "page.products.efms.workorder.pic1" })}
          </p>
          <Img
            className={cx(
              css`
                width: 599px;
                &.gatsby-image-wrapper img {
                  object-fit: contain !important;
                }
              `,
              "max-w-full"
            )}
            fluid={main}
            alt={formatMessage({ id: "page.products.efms.workorder.pic1" })}
          />
        </div>
        <div data-sal="slide-left" className="max-w-full" data-sal-duration="500">
          <p className="font-bold mb-7">
            <ListIcon />
            {formatMessage({ id: "page.products.efms.workorder.pic2" })}
          </p>
          <Img
            className={cx(
              css`
                width: 194px;
              `,
              "max-w-full"
            )}
            fluid={main2}
            alt={formatMessage({ id: "page.products.efms.workorder.pic2" })}
          />
        </div>
        <div data-sal="slide-left" className="max-w-full" data-sal-duration="500">
          <p className="font-bold mb-7">
            <ListIcon />
            {formatMessage({ id: "page.products.efms.workorder.pic3" })}
          </p>
          <Img
            className={cx(
              css`
                width: 376px;
              `,
              "max-w-full"
            )}
            fluid={main3}
            alt={formatMessage({ id: "page.products.efms.workorder.pic3" })}
          />
        </div>
      </div>
    </Box>
  );
};

const ModuleEnergy = ({ main }) => {
  const { formatMessage } = useIntl();

  const data = {
    boxClassNames: "lg:max-w-xl lg:w-72 lg:py-0 lg:px-0",
    mainImgClassNames: "max-w-4xl",
    title: formatMessage({ id: "page.products.efms.energy" }),
    list: [
      {
        desc: [formatMessage({ id: "page.products.efms.energy.list.desc1" })],
      },
      {
        desc: [formatMessage({ id: "page.products.efms.energy.list.desc2" })],
      },
      {
        desc: [formatMessage({ id: "page.products.efms.energy.list.desc3" })],
      },
      {
        desc: [formatMessage({ id: "page.products.efms.energy.list.desc4" })],
      },
    ],
  };
  return <Templete main={main} data={data} col={1} boxClassNames={data.boxClassNames} layout="right" />;
};

const ModuleBus = ({ main, bg }) => {
  const { formatMessage } = useIntl();

  const data = {
    title: formatMessage({ id: "page.products.efms.bus" }),
    desc: [
      formatMessage({ id: "page.products.efms.bus.desc1" }),
      formatMessage({ id: "page.products.efms.bus.desc2" }),
      formatMessage({ id: "page.products.efms.bus.desc3" }),
    ],
    list: [
      {
        title: formatMessage({ id: "page.products.efms.bus.list.title1" }),
        desc: [formatMessage({ id: "page.products.efms.bus.list.desc1" })],
        col: 2,
      },
      {
        title: formatMessage({ id: "page.products.efms.bus.list.title2" }),
        desc: [formatMessage({ id: "page.products.efms.bus.list.desc2" })],
      },
      {
        title: formatMessage({ id: "page.products.efms.bus.list.title3" }),
        desc: [formatMessage({ id: "page.products.efms.bus.list.desc3" })],
      },
    ],
  };

  return <Templete bg={bg} main={main} data={data} />;
};

const Modules = () => {
  const assets = useStaticQuery(graphql`
    query {
      visitor_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_visitor" }) {
        ...squareImage
      }
      visitor_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_visitor" }) {
        ...squareImage
      }
      visitor_bg: file(relativeDirectory: { eq: "efms" }, name: { eq: "module_visitor_bg" }) {
        ...squareImage
      }
      inspection_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_inspection" }) {
        ...squareImage
      }
      inspection_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_inspection" }) {
        ...squareImage
      }
      eqp_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_eqp" }) {
        ...squareImage
      }
      eqp_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_eqp" }) {
        ...squareImage
      }
      eqp_bg: file(relativeDirectory: { eq: "efms" }, name: { eq: "module_spare_bg" }) {
        ...squareImage
      }
      construction_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_construction" }) {
        ...squareImage
      }
      construction_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_construction" }) {
        ...squareImage
      }
      construction2_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_construction2" }) {
        ...squareImage
      }
      construction2_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_construction2" }) {
        ...squareImage
      }
      meal_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_meal" }) {
        ...squareImage
      }
      meal_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_meal" }) {
        ...squareImage
      }
      meal_bg: file(relativeDirectory: { eq: "efms" }, name: { eq: "module_meal_bg" }) {
        ...squareImage
      }
      desk_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_desk_1" }) {
        ...squareImage
      }
      desk_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_desk_1" }) {
        ...squareImage
      }
      desk2_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_desk_2" }) {
        ...squareImage
      }
      desk2_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_desk_2" }) {
        ...squareImage
      }
      order_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_order" }) {
        ...squareImage
      }
      order_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_order" }) {
        ...squareImage
      }
      order2_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_order1" }) {
        ...squareImage
      }
      order2_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_order1" }) {
        ...squareImage
      }
      order3_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_order2" }) {
        ...squareImage
      }
      order3_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_order2" }) {
        ...squareImage
      }
      order_bg: file(relativeDirectory: { eq: "efms" }, name: { eq: "module_order_bg" }) {
        ...squareImage
      }
      energy_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_energy" }) {
        ...squareImage
      }
      energy_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_energy" }) {
        ...squareImage
      }
      bus_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "module_bus" }) {
        ...squareImage
      }
      bus_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "module_bus" }) {
        ...squareImage
      }
      bus_bg: file(relativeDirectory: { eq: "efms" }, name: { eq: "module_bus_bg" }) {
        ...squareImage
      }
    }
  `);
  const { locale } = useIntl();
  const {
    visitor_zh = {},
    visitor_en = {},
    visitor_bg = {},
    inspection_zh = {},
    inspection_en = {},
    eqp_zh = {},
    eqp_en = {},
    eqp_bg = {},
    construction_zh = {},
    construction_en = {},
    construction2_zh = {},
    construction2_en = {},
    meal_zh = {},
    meal_en = {},
    meal_bg = {},
    desk_zh = {},
    desk_en = {},
    desk2_zh = {},
    desk2_en = {},
    order_zh = {},
    order_en = {},
    order2_zh = {},
    order2_en = {},
    order3_zh = {},
    order3_en = {},
    order_bg = {},
    energy_zh = {},
    energy_en = {},
    bus_zh = {},
    bus_en = {},
    bus_bg = {},
  } = assets;

  return (
    <div className="text-center">
      <ModuleVisitor main={getImageI18nFluid({ zh: visitor_zh, en: visitor_en }, locale)} bg={visitor_bg} />
      <ModuleInspection main={getImageI18nFluid({ zh: inspection_zh, en: inspection_en }, locale)} />
      <ModuleEqp main={getImageI18nFluid({ zh: eqp_zh, en: eqp_en }, locale)} bg={eqp_bg} />
      <ModuleConstruction
        main={getImageI18nFluid({ zh: construction_zh, en: construction_en }, locale)}
        main2={getImageI18nFluid({ zh: construction2_zh, en: construction2_en }, locale)}
      />
      <ModuleMeal main={getImageI18nFluid({ zh: meal_zh, en: meal_en }, locale)} bg={meal_bg} />
      <ModuleDesk
        main={getImageI18nFluid({ zh: desk_zh, en: desk_en }, locale)}
        main2={getImageI18nFluid({ zh: desk2_zh, en: desk2_en }, locale)}
      />
      <ModuleOrder
        main={getImageI18nFluid({ zh: order_zh, en: order_en }, locale)}
        main2={getImageI18nFluid({ zh: order2_zh, en: order2_en }, locale)}
        main3={getImageI18nFluid({ zh: order3_zh, en: order3_en }, locale)}
        bg={order_bg}
      />
      <ModuleEnergy main={getImageI18nFluid({ zh: energy_zh, en: energy_en }, locale)} />
      <ModuleBus main={getImageI18nFluid({ zh: bus_zh, en: bus_en }, locale)} bg={bus_bg} />
      <OtherModules />
    </div>
  );
};

export default Modules;
