import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import OurAdvantages from "@/data/products/efms/ourAdvantages";
import Modules from "@/data/products/efms/modules";
import H1Title from "@/components/H1Title";
import SubTitle from "@/components/SubTitle";
import { getImageI18nFluid, squareImage } from "@/utils/transformer";
import Img from "gatsby-image";

const ProductsEfmsPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      field: allFile(filter: { relativeDirectory: { eq: "products/efms/material/field" } }) {
        nodes {
          id
          publicURL
          name
          ...squareImage
        }
      }
      modules_zh: file(relativeDirectory: { eq: "zh/efms" }, name: { eq: "modules" }) {
        ...squareImage
      }
      modules_en: file(relativeDirectory: { eq: "en/efms" }, name: { eq: "modules" }) {
        ...squareImage
      }
    }
  `);
  const { field = {}, modules_zh = {}, modules_en = {} } = data;
  const { formatMessage, locale } = useIntl();

  console.log(locale);
  return (
    <div className="min-h-screen bg-whiter relative flex flex-col">
      <main className="flex-auto">
        <div className="w-full">
          <div className="max-w-7xl mx-auto py-8 px-4 2xl:px-0 md:py-12 lg:py-16 xl:py-24">
            <div className="text-center mb-4">
              <H1Title>
                {formatMessage({
                  id: "menu.products.efms",
                })}
              </H1Title>
              <p
                data-sal="slide-up"
                className="text-base color-gray-66 text-left py-4 md:py-6 lg:py-9 mx-auto leading-9"
                style={{
                  textIndent: "2rem",
                }}
              >
                {formatMessage({ id: "page.products.efms.introduce" })}
              </p>
              <div>
                <SubTitle>{formatMessage({ id: "page.products.efms.fnModules" })}</SubTitle>
                <Img
                  data-sal="fade"
                  data-sal-duration="500"
                  className="w-full"
                  fluid={getImageI18nFluid({ zh: modules_zh, en: modules_en }, locale)}
                />
              </div>
            </div>
          </div>
        </div>
        <Modules />
        <div className="max-w-7xl mx-auto mb-8 xl:mb-20 px-4 md:px-6 lg:px-0">
          <SubTitle>{formatMessage({ id: "page.products.efms.field" })}</SubTitle>
          <div className="flex flex-col md:flex-row gap-4 justify-between items-center mt-4 xl:mt-14">
            {field?.nodes
              ?.sort((j, i) => j.name.split("_")[0] - i.name.split("_")[0])
              ?.map((i, j) => (
                <div key={j} className="relative overflow-hidden" data-sal="slide-up" data-sal-duration={200 + 100 * j}>
                  <Img
                    className="hover:scale-110 transform transition-all cursor-pointer duration-300 w-96 h-64"
                    fluid={i?.childImageSharp?.fluid}
                    alt={i.name}
                  />

                  <div
                    className="text-center py-3 absolute bottom-0 left-0 right-0 text-white"
                    style={{ background: "rgba(0,0,0,0.4)" }}
                  >
                    {(() => {
                      switch (j) {
                        case 0:
                          return <p>{formatMessage({ id: "page.products.efms.factory" })}</p>;
                        case 1:
                          return <p>{formatMessage({ id: "page.products.efms.administrative" })}</p>;
                        case 2:
                          return <p>{formatMessage({ id: "page.products.efms.safety" })}</p>;
                      }
                    })()}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <OurAdvantages />
      </main>
    </div>
  );
};

export default ProductsEfmsPage;
