import React from "react";
import { graphql } from "gatsby";
import { Footer, ApplyFooter } from "@/components";
import { Amap, Marker, config } from "@amap/amap-react";
import cooperation from "@/static/icons/cooperation.png";
import tech from "@/static/icons/tech.png";
import { useIntl } from "gatsby-plugin-intl";

config.key = "5c5593d1af9a041c7dcacb12d0a8fbf2";

const isBrowser = typeof window !== "undefined";

const ContactPage = (props) => {
  const { data = {} } = props;
  const { banner = {} } = data;
  const { formatMessage } = useIntl();

  return (
    <div className="bg-whiter relative flex flex-col">
      <main className="flex-auto">
        <div className="w-full h-auto">
          <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 py-10 sm:py-20">
            <div className="col-start-1 row-start-2 sm:row-start-1 pt-10 sm:pt-0 h-80 sm:h-100">
              {isBrowser && (
                <Amap zoom={16} center={["120.732351", "31.263252"]}>
                  <Marker
                    position={["120.732351", "31.263252"]}
                    label={{
                      content: formatMessage({ id: "global.mjk" }),
                      direction: "bottom",
                    }}
                    onClick={() => {
                      window.open("https://ditu.amap.com/place/B0FFLKEJIU");
                    }}
                  />
                </Amap>
              )}
            </div>
            <div className="px-4 sm:pt-4 lg:pl-8 lg:pt-6 xl:pl-10 2xl:pl-14 xl:pt-7">
              <h1 className="text-xl md:text-2xl lg:text-2.5xl">{formatMessage({ id: "menu.contact" })}</h1>
              <div
                className="flex mt-9"
                style={{
                  minHeight: 100,
                  border: "1px solid #E8E8E8",
                }}
              >
                <div
                  className="flex flex-col justify-center items-center bg-gray-f9 w-20"
                  style={{
                    minWidth: 92,
                  }}
                >
                  <img className="w-6 mr-2" src={cooperation} alt="cooperation" />
                  <span className="text-sm">{formatMessage({ id: "page.contact.business.cooperation" })}</span>
                </div>
                <div className="flex items-center pl-8 pr-4 py-2">
                  <ul className="text-sm leading-6 break-all">
                    <li>
                      {formatMessage({ id: "page.contact.contact" })}：{formatMessage({ id: "page.contact.lian" })}
                    </li>
                    <li>
                      {formatMessage({ id: "global.email.index" })}
                      ：chunhong.lian@mjk24.com
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="flex mt-6"
                style={{
                  minHeight: 100,
                  border: "1px solid #E8E8E8",
                }}
              >
                <div
                  className="flex flex-col justify-center items-center bg-gray-f9 w-20"
                  style={{
                    minWidth: 92,
                  }}
                >
                  <img className="w-5 mr-2" src={tech} alt="tech" />
                  <span className="text-sm">{formatMessage({ id: "page.contact.tech" })}</span>
                </div>
                <div className="flex items-center pl-8 pr-4 py-2">
                  <ul className="text-sm leading-6 break-all">
                    <li>
                      {formatMessage({ id: "page.contact.contact" })}：{formatMessage({ id: "page.contact.liu" })}
                    </li>
                    <li>
                      {formatMessage({ id: "global.email.index" })}
                      ：jing.liu@mjk24.com
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div
                className="flex mt-5"
                style={{
                  minHeight: 80,
                  border: "1px solid #E8E8E8",
                }}
              >
                <div
                  className="flex flex-col justify-center items-center bg-gray-f9 w-20"
                  style={{
                    minWidth: 80,
                  }}
                >
                  <img
                    className="w-5 mr-2"
                    src={recruitment}
                    alt="recruitment"
                  />
                  <span className="text-sm">
                    {formatMessage({ id: "page.contact.recruitment" })}
                  </span>
                </div>
                <div className="flex items-center pl-8 pr-4 flex-1 text-sm leading-6 py-2">
                  <div className="w-full">
                    <div className="w-40 float-left">
                      <span className="whitespace-nowrap">
                        {formatMessage({ id: "page.contact.contact" })}：
                      </span>
                      <span className="break-all">
                        {formatMessage({ id: "page.contact.miss_lian" })}
                      </span>
                    </div>
                    <div className="float-left">
                      <span className="whitespace-nowrap">
                        {formatMessage({ id: "global.email.index" })}：
                      </span>
                      <span className="break-all">caifeng.lian@mjk24.com</span>
                    </div>
                    <div className="w-40 float-left">
                      <span className="whitespace-nowrap">
                        {formatMessage({ id: "global.tel.index" })}：
                      </span>
                      <span className="break-all">0512-62920285</span>
                    </div>
                    <div className="float-left">
                      <span className="whitespace-nowrap">
                        {formatMessage({ id: "global.address.index" })}：
                      </span>
                      <span className="break-all">
                        {formatMessage({ id: "page.contact.address" })}
                      </span>
                    </div>
                  </div>

                  {/* <table className="w-full text-sm">
                    <tr>
                      <td>联系人：连女士</td>
                      <td>
                        <span className="whitespace-nowrap">邮箱：</span>
                        <span>caifeng.lian@mjk24.com</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="whitespace-nowrap">座机：</span>
                        <span>0512-62920285</span>
                      </td>
                      <td>
                        <span className="whitespace-nowrap">地址：</span>
                        <span>苏州市工业园区创意产业园16栋B503</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>
      <Footer>
        <ApplyFooter type="1" />
      </Footer>
    </div>
  );
};

export const query = graphql`
  query {
    banner: file(relativeDirectory: { eq: "banners" }, name: { eq: "contact" }) {
      id
      publicURL
    }
  }
`;

export default ContactPage;
