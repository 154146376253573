/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-06-29 11:41:10
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-07-19 16:09:39
 */
import React from "react";
import { cx, css } from "@emotion/css";
import Img from "gatsby-image";

const Box = (props) => {
  const { bg, children } = props;
  return (
    <div
      className={cx(
        "relative",
        bg &&
          typeof bg === "string" &&
          css`
            background-image: url(${bg});
            background-size: cover;
            background-repeat: no-repeat;
            @media screen and (max-width: 640px) {
              background: none;
            }
          `
      )}
    >
      {bg && typeof bg !== "string" && (
        <div
          className={cx(
            "w-full h-full left-0 right-0 absolute hidden sm:block",
            css`
              z-index: -1;
            `
          )}
        >
          <Img className={"w-full h-full"} fluid={bg?.childImageSharp?.fluid} />
        </div>
      )}
      <div className="w-full py-3 md:py-10 lg:py-20 lg:px-4">{children}</div>
    </div>
  );
};

export default Box;
